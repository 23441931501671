import React from 'react'
import { Redirect } from 'react-router-dom'
import { LayoutLoggedOut as Layout } from 'app/theme/layout'
import { Helmet } from 'react-helmet'
import Hero from './homepage/Hero'
import WhyWorkWithUs from './homepage/WhyWorkWithUs'
import Testimonials from './homepage/Testimonials'
import HowItWorks from './homepage/HowItWorks'
import ContentGrids from './homepage/ContentGrids'
import Occasions from './homepage/Occasions'
import Features from './homepage/Features'

export default function HomePage({ session }) {
  if (session.isLoggedIn) {
    return <Redirect to="/catering" />
  }

  return (
    <Layout>
      <Helmet>
        <title>Vendor Portal - Roaming Hunger</title>
        <meta name="description" content="Roaming Hunger provides growth opportunities for your mobile business with our Booking Marketplace and Toolkit." />
        <meta name="og:title" content="Vendor Portal - Roaming Hunger"></meta>
      </Helmet>

      <Hero />
      <WhyWorkWithUs />
      <Testimonials />
      <HowItWorks />
      <ContentGrids />
      <Occasions />
      <Features />
    </Layout>
  )
}
